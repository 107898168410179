<template>
	<main>
		<section class="layout-full_width" >
			<h1 class="page-title">
				<!-- TODO: -->
				<!-- <nice-icon2
					icon="campaigns"
					class="page-title-icon"
				/> -->
				{{ $t('page_caption_campaign-list') }}
			</h1>


			<!-- selected campaigns stats -->
			<div class="campaigns-summary cl-container cl-pad">
				<!-- budget -->
				<div class="detail detail-huge cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.budget') | capitalize" />
					<span class="value">
						<nice-icon-2
							v-if="!isSSPViewer"
							icon="diagram"
							:state="budgetSpentTotalPercent"
							class="value-icon"
						/>
						<span v-if="!isSSPViewer">{{ budgetSpentTotal | formatMoney('') }}</span>
						<span class="shaded">
							<span v-if="!isSSPViewer">/</span>
							{{ budgetTotal | parseFloat }}
						</span>
						<span class="units">{{ appAgency.currency | formatCurrency }}</span>
					</span>
				</div>

				<!-- impressions -->
				<!-- <div class="detail detail-huge cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.impressions') | capitalize" />
					<span class="value">
						<span>{{ impressionsTotal | parseFloat }}</span>
					</span>
				</div> -->

				<!-- ad plays -->
				<!-- <div class="detail detail-huge cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.ad_plays') | capitalize" />
					<span class="value">
						<span>{{ adPlaysTotal | parseFloat }}</span>
					</span>
				</div> -->

				<!-- docs -->
				<div class="button-wrapper cl-xs3 cl-pad">
					<nice-button-2
						class="download-button"
						icon="load-24"
						@click="generateBroadcastingReport"
					>
						{{ $t('campaign.download_broadcasting_report') }}
					</nice-button-2>
				</div>

				<div class="button-wrapper cl-xs3 cl-pad">
					<nice-button-2
						class="download-button"
						icon="load-24"
						@click="generateCampaignReport"
					>
						{{ $t('campaign.download_campaign_report') }}
					</nice-button-2>
				</div>
			</div>


			<CampaignTableTopPanel
				v-model="filterOptions"
				:show-only-my="isDSP"
				:statusList="statusFilter"
			/>

			<table class="nice-table"
				@mouseover="loadStats"
			>
				<thead>
					<tr>
						<th>{{ $t('request_list.id') }}&nbsp;&mdash;</th>
						<th>{{ $t('request_list.name') }}&nbsp;&mdash;</th>
						<th v-if="appSide == 'ssp'">{{ $t('request_list.dsp_agency') }}&nbsp;&mdash;</th>
						<th class="header-status">{{ $t('request_list.status') }}&nbsp;&mdash;</th>
						<th>{{ $t('request_list.budget') }}&nbsp;&mdash;</th>
						<th><span>{{ $t('request_list.imp_ad_plays') }}</span>&nbsp;&mdash;</th>
						<th>{{ $t('request_list.devices') }}&nbsp;&mdash;</th>
						<th>{{ $t('request_list.start') }}&nbsp;&mdash;</th>
						<th>{{ $t('request_list.end') }}&nbsp;&mdash;</th>
						<th>{{ $t('request_list.created') }}&nbsp;&mdash;</th>
						<th>{{ $t('request_list.updated') }}&nbsp;&mdash;</th>
					</tr>
				</thead>

				<tbody>
					<template
						v-if="campaignListTotalCount"
					>
						<tr
							v-for="campaign in campaignList"
							:key="campaign.id"
							:class="`campaign_${campaign.id}_row`"
						>
							<td>{{ campaign.id }}</td>
							<table-cell-name
								:id="campaign.id"
								resource="campaign"
								:caption="campaign.name"
								:router="{
									'name': 'campaign-details',
									'params': { id: campaign.id },
								}"
								show-link
							/>
							<td v-if="appSide == 'ssp'">
								{{ campaign.owner_instance.name }}
							</td>
							<table-cell-campaign-audits
							  :campaign-id="campaign.id"
								:audits="getStatus(campaign)"
								:statuses="actions"
							/>
							<!-- <table-cell-status
								resource="campaign"

								:item="campaign"
								:list="getStatusListForChange(campaign.status)"

								:can-change="canChange(campaign.status)"
								icons-in-list
								class="col-status"
							/> -->
							<td class="col-budget">
								<template v-if="!isSSPViewer">
									<nice-icon-2
										icon="diagram"
										:state="campaign.spent / campaign.budget * 100"
									/>
									<span
										v-html="formatCashSpent(campaign.spent, campaign.budget, campaign.currency)"
									/>
								</template>
								<span
									v-else
									v-html="formatCash(campaign.budget, campaign.currency)"
								/>
							</td>

							<td class="col-impressions">
								<span v-if="campaign.payment_model_counted">
									{{ Math.floor(campaign.payment_model_counted) }}
								</span>
								<span v-else>—</span>
							</td>

							<td>
								{{ campaign.device_count }}
							</td>
							<td>{{ campaign.start_date }}</td>
							<td>{{ campaign.end_date }}</td>

							<table-cell-author :author="campaign.created_by" :datetime="campaign.ctime" />
							<table-cell-author :author="campaign.modified_by" :datetime="campaign.mtime" />
						</tr>
					</template>

					<tr
						v-else
						class="table-empty"
					>
						<td colspan="10" >Campaign empty</td>
					</tr>
				</tbody>
			</table>

			<table-paginator
				v-model="pagination"
				:total="campaignListTotalCount"
				:paginator-props="{ queryKey: 'p' }"
			/>
		</section>
	</main>
</template>

<script>
import * as moment from 'moment';
import _ from 'underscore';
import { mapState, mapGetters, mapActions } from 'vuex';

import TableCellName from '@/components/table/cell-name';
import TableCellAuthor from '@/components/table/cell-author';
// import TableCellStatus from '@/components/table/cell-status';
import TableCellCampaignAudits from '@/components/table/cell-campaign-audits';
import CampaignTableTopPanel from './table-top-panel.vue';
import TablePaginator from '@/components/table-paginator.vue';

import { send } from '@/api/request';

import {
	formatCash,
	formatCashSpent,
} from '@/utilites';
import {
	APPROVED as STATUS_PLAYING,
	PARTIALLY_APPROVED_EXTENDED_APPROVED as STATUS_PARTIALLY_PLAYING,
	PENDING_AUDIT_EXTENDED_APPROVED as STATUS_PLAYING_AWAITING_APPROVAL,
	PAUSED as STATUS_PAUSED,
	FINISHED as STATUS_FINISHED,
	ARCHIVED as STATUS_ARCHIVED,
	PENDING_AUDIT as STATUS_AWAITING_APPROVAL,
	DENIED as STATUS_REJECTED,
	DELETED as STATUS_DELETED,
	// TODO: something like components/creative/audit-statuses.js?
	CAMPAIGN_AUDIT_STATUSES,
} from '@/constants/audit-status-codes';

import { getExtendedStatus } from '@/constants/utils';

const STATUS_CHANGE_PLAYING = {
	value: STATUS_PLAYING,
	icon: 'play',
};
const STATUS_CHANGE_PARTIALLY_PLAYING = {
	value: STATUS_PARTIALLY_PLAYING,
	icon: 'warn',
};
const STATUS_CHANGE_PLAYING_AWAITING_APPROVAL = {
	value: STATUS_PLAYING_AWAITING_APPROVAL,
	icon: 'warn',
};
const STATUS_CHANGE_PAUSED = {
	value: STATUS_PAUSED,
	icon: 'pause',
};
const STATUS_CHANGE_FINISHED = {
	value: STATUS_FINISHED,
	icon: 'check',
};
const STATUS_CHANGE_AWAITING_APPROVAL = {
	value: STATUS_AWAITING_APPROVAL,
	icon: 'warn',
};
const STATUS_CHANGE_REJECTED = {
	value: STATUS_REJECTED,
	icon: 'error',
};
const STATUS_CHANGE_ARCHIVED = {
	value: STATUS_ARCHIVED,
	icon: 'arrow_circle',
};
const STATUS_CHANGE_DELETED = {
	value: STATUS_DELETED,
	icon: 'basket',
};


// days
const DEFAULT_DATE_PERIOD = 7;
const DATE_FORMAT = 'YYYY-MM-DD';


export default {
	name: 'PageCampaignList',


	components: {
		// TableCellStatus,
		TableCellCampaignAudits,
		TablePaginator,
		TableCellAuthor,
		TableCellName,
		CampaignTableTopPanel,
	},


	filters: {
		parseFloat(value) {
			const number = parseFloat(value);
			if (isNaN(number)) {
				return value;
			}
			return parseFloat(number.toFixed(4));
		},
	},


	data() {
		// this.$route.query.df, this.$route.query.dt
		let dateTill = this.$route.query.dt ? moment(this.$route.query.dt) : moment();
		let dateFrom = this.$route.query.df ? moment(this.$route.query.df) : moment(dateTill).subtract(DEFAULT_DATE_PERIOD, 'days');

		return {
			filterOptions: {
				// default date range filtration
				date_from: dateFrom.format(DATE_FORMAT),
				date_till: dateTill.format(DATE_FORMAT),
			},
			statsIsLoading: false,

			CAMPAIGN_AUDIT_STATUSES,
			STATUS_PLAYING,
			STATUS_PAUSED,
			STATUS_FINISHED,
			STATUS_AWAITING_APPROVAL,
			STATUS_REJECTED,
			STATUS_ARCHIVED,
			STATUS_DELETED,
		};
	},


	computed: {
		...mapGetters('app', [
		  'isSSPAdminOrApprover',
		  'isSSPViewer',
		  'isDSP',
		]),

		...mapState('app', {
			appAgency: 'agency',
			appSide: 'side',
		}),

		...mapState('campaign', {
			campaignList: 'list',
			campaignListTotalCount: 'totalCount',
			campaignListPage: 'page',
			campaignListPageSize: 'pageSize',
			campaignListMeta: 'meta',
		}),


		// stats for the list
		budgetSpentTotalPercent() {
			return this.campaignListMeta?.budget_spent_total_percent || 0;
		},
		budgetSpentTotal() {
			return this.campaignListMeta?.budget_spent_total || 0;
		},
		budgetTotal() {
			return this.campaignListMeta?.budget_total || 0;
		},
		// TODO: no data yet
		impressionsTotal() {
			return 0;
		},
		adPlaysTotal() {
			return 0;
		},


		actions() {
			return [
				STATUS_CHANGE_PLAYING,
				STATUS_CHANGE_PLAYING_AWAITING_APPROVAL,
				STATUS_CHANGE_PARTIALLY_PLAYING,
				STATUS_CHANGE_PAUSED,
				STATUS_CHANGE_FINISHED,
				STATUS_CHANGE_AWAITING_APPROVAL,
				STATUS_CHANGE_REJECTED,
				STATUS_CHANGE_ARCHIVED,
				STATUS_CHANGE_DELETED,
			].reduce((actions, status) => {
				actions[status.value] = {
					value: status.value,
					icon: status.icon,
					label: this.$t('campaign.status_change_' + status.value),
					labelAction: this.$t('campaign.status_change_' + status.value + '_action'),
				};
				return actions;
			}, {});
		},

		/**
		 * Return statuses for filter
		 */
		statusFilter() {
			const capitalize = this.$options.filters.capitalize;
			const actions = this.actions;
			let result = [
				{
					value: '---',
					label: capitalize(this.$t('campaign.all_campaigns'))
				},
				{
					value: STATUS_PLAYING,
					label: capitalize(actions[STATUS_PLAYING].label)
				},
				{
					value: STATUS_PAUSED,
					label: capitalize(actions[STATUS_PAUSED].label)
				},
				{
					value: STATUS_AWAITING_APPROVAL,
					label: capitalize(actions[STATUS_AWAITING_APPROVAL].label)
				},
				{
					value: STATUS_FINISHED,
					label: capitalize(actions[STATUS_FINISHED].label)
				},
			];

			if (this.isDSP) {
				result.push({
					value: STATUS_ARCHIVED,
					label: capitalize(actions[STATUS_ARCHIVED].label),
				});
			}

			return result;
		},

		pagination: {
			get() {
				return {
					page: this.campaignListPage,
					pageSize: this.campaignListPageSize,
				};
			},

			set(pagination) {
				this.requestCampaignWithLoading({
					filters: this.filterOptions,
					...pagination,
				});
			},
		},
	},


	methods: {
		...mapActions('campaign', {
			'requestCampaignList': 'requestList',
			'requestCampaignStatList': 'requestStatList',
			// 'requestCampaignChange': 'change'
		}),

		getStatus(campaign) {
			if (campaign.status.length == 0) return campaign.status;
			const clonedStatus = { ...campaign.status[0] };
			clonedStatus.status = getExtendedStatus(campaign);
			return [clonedStatus];
		},

		formatCash,
		formatCashSpent,

		async requestCampaignWithLoading(options) {
			let loading = this.$loading.show();
			await this.requestCampaignList(options);
			loading.hide();
			this.statsIsLoading = false;
		},

		async loadStats() {
			if (this.statsIsLoading) {
				return;
			}

			this.statsIsLoading = true;
		
			await this.requestCampaignStatList({
				filters: this.filterOptions,
				...this.pagination,
			});
		},

		/**
		 * Return list of available statuses for change
		 */
		getStatusListForChange(status) {
			let result = [];

			if (this.isSSPAdminOrApprover) {
				switch (status) {
				case STATUS_PLAYING:
					result = [
						STATUS_CHANGE_PAUSED,
						STATUS_CHANGE_AWAITING_APPROVAL,
						STATUS_CHANGE_PLAYING,
					];
					break;

				case STATUS_PAUSED:
					result = [
						STATUS_CHANGE_PLAYING,
						STATUS_CHANGE_AWAITING_APPROVAL,
						STATUS_CHANGE_PAUSED,
					];
					break;

				case STATUS_AWAITING_APPROVAL:
					result = [
						STATUS_CHANGE_PLAYING,
						STATUS_CHANGE_PAUSED,
						STATUS_CHANGE_AWAITING_APPROVAL,
					];
					break;

				// Just for dislay. See `canChange` method
				default:
					result = [
						STATUS_CHANGE_FINISHED,
						STATUS_CHANGE_ARCHIVED,
						STATUS_CHANGE_DELETED,
					];
					break;
				}
			}
			// DSP
			else {
				switch (status) {
				case STATUS_PLAYING:
					result = [
						STATUS_CHANGE_PAUSED,
						STATUS_CHANGE_FINISHED,
						STATUS_CHANGE_PLAYING,
					];
					break;

				case STATUS_PAUSED:
					result = [
						STATUS_CHANGE_PLAYING,
						STATUS_CHANGE_FINISHED,
						STATUS_CHANGE_PAUSED,
					];
					break;

				case STATUS_FINISHED:
					result = [
						STATUS_CHANGE_ARCHIVED,
						STATUS_CHANGE_FINISHED,
					];
					break;

				case STATUS_ARCHIVED:
					result = [
						// STATUS_CHANGE_AWAITING_APPROVAL,
						STATUS_CHANGE_DELETED,
						STATUS_CHANGE_ARCHIVED,
					];
					break;

				case STATUS_AWAITING_APPROVAL:
					result = [
						STATUS_CHANGE_ARCHIVED,
						STATUS_CHANGE_AWAITING_APPROVAL,
					];
					break;

				// case STATUS_REJECTED:
				// 	result = [
				// 		STATUS_CHANGE_AWAITING_APPROVAL,
				// 		STATUS_CHANGE_ARCHIVED,
				// 		STATUS_CHANGE_REJECTED,
				// 	];
				// 	break;
				}
			}

			//оборачиваем вызовом ключа из системы переводов
			const finalResult = result.map((el) => this.actions[el.value]);
			return finalResult;
		},

		/**
		 * TODO: get from `getStatusListForChange()`?
		 */
		canChange(status) {
			if (this.isSSPAdminOrApprover) {
				return _.contains([ STATUS_PLAYING, STATUS_PAUSED, STATUS_AWAITING_APPROVAL ], status);
			}

			return status != STATUS_DELETED;
		},


		/**
		 * Generate broadcasting report for selected campaigns
		 */
		async generateBroadcastingReport() {
			const res = await send(this.campaignListMeta?.broadcasting_report_url);
			this.showGenerationMessage(res);
		},

		async generateCampaignReport() {
			const res = await send(this.campaignListMeta?.broadcasting_report_url + '&report_type=campaign_report');
			this.showGenerationMessage(res);
		},

		showGenerationMessage(res) {
			if (res.data.status == 'ok') {
				window.alert(this.$t('campaign.report_generation_started'));
			}
			else {
				window.alert(this.$t('campaign.report_generation_failed'));
			}
		},

	},  // END: methods


	watch: {
		appAgency(value, oldValue) {
			if (value && value !== oldValue) {
				this.requestCampaignWithLoading({ filters: this.filterOptions, force: true });
			}
		},

		filterOptions: {
			deep: true,
			handler() {
				if (this.appAgency) {
					this.requestCampaignWithLoading({ filters: this.filterOptions, force: true });
				}
			}
		},
	},
};
</script>

<!-- TODO: why global scope? -->
<style lang="sass" >
.tippy-popper
	.ni_action_list--button-status_change
		&.action-finished
			--ni-icon-base: #{$nice_color-navy}
			--ni-icon-sign: #{$nice_color-navy}
</style>

<style lang="sass" scoped >
.page-title
	font-weight: 400
	font-size: 18px
	line-height: 21px
	text-transform: capitalize

	.page-title-icon
		--ni-icon-sign: black
		vertical-align: middle
		margin-left: -8px


.col-status
	&.status-finished
		::v-deep
			.ni_table_c_status--label-icon,
			.ni_action_list--icon
				--ni-icon-base: #{$nice_color-navy}
				--ni-icon-sign: #ffffff


// olds styles
.col-budget,
.col-impressions
	::v-deep & >
		*
			display: inline-block
			vertical-align: middle
			line-height: 16px
			height: 16px
			padding: 2px

		.nice-icon
			padding-right: 0

		span
			margin-left: .8em
			text-transform: capitalize
			padding-left: 0

.button-status_change
	+button__clear

	.nice-icon
		display: block

.status_change-wrapper
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start

.status_change
	@extend %button__text

	display: flex
	flex-direction: row
	justify-content: center
	align-items: flex-start

	padding: 5px
	font-size: 11px
	text-transform: capitalize
	color: $nice_color-semidarkgray

	.nice-icon
		margin-right: 10px

.table-empty
	text-align: center


.campaigns-summary
	margin-bottom: 35px
	padding-bottom: 35px
	border-bottom: 1px solid $nice_color-gray

	.detail
		display: flex
		flex-direction: column

		.value
			display: flex
			align-items: center
			width: 100%
			line-height: 18px
			margin: (30px - 18px) / 2 0
			text-overflow: ellipsis

			&:not(.multiline)
				white-space: nowrap
				overflow: hidden

			& > *
				margin-left: .5em

			& > :first-child
				margin-left: 0

			& > .units,
			& > .shaded
				color: var(--text_2_color)

	.detail-huge
		& > .label
			font-size: 11px

		& > .value
			font-weight: 300
			font-size: 16px

	.download-button
		max-height: 24px
		min-height: unset
		height: 24px
		box-sizing: border-box
		color: var(--text_2_color)
		margin-top: 14px
</style>
